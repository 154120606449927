// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@assets/imgs/salita/dashboard_icons/lang_icon.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("@assets/imgs/salita/dashboard_icons/check_mark_icon.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, "\n.language-dropdown[data-v-bb78a1dc] {\n  position: relative;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  margin: 0 20px;\n}\n.language-dropdown__btn[data-v-bb78a1dc] {\n  height: 100%;\n  padding-left: 25px;\n  border-bottom: 2px transparent solid;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-position: 0 50%;\n  background-size: 20px auto;\n  background-repeat: no-repeat;\n  font-weight: 400;\n}\n.language-dropdown__btn--active[data-v-bb78a1dc] {\n  border-bottom: 2px black solid;\n}\n.language-dropdown__list[data-v-bb78a1dc] {\n  position: absolute;\n  top: 65px;\n  z-index: 3;\n  width: 160px;\n  border-radius: 8px;\n  background: #fff;\n  -webkit-box-shadow: 0 24px 64px -12px rgba(16, 24, 40, 0.14), 0 1px 3px 0 rgba(16, 24, 40, 0.1), 0 1px 2px 0 rgba(16, 24, 40, 0.06);\n          box-shadow: 0 24px 64px -12px rgba(16, 24, 40, 0.14), 0 1px 3px 0 rgba(16, 24, 40, 0.1), 0 1px 2px 0 rgba(16, 24, 40, 0.06);\n}\n.dropdown-list__wrapper[data-v-bb78a1dc] {\n  padding: 8px;\n}\n.dropdown-list__item[data-v-bb78a1dc] {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  width: 100%;\n  padding: 8px;\n  border-radius: 8px;\n}\n.dropdown-list__item[data-v-bb78a1dc]:hover {\n  background-color: #f4f5f7;\n  -webkit-transition: ease-in-out 0.125s;\n  transition: ease-in-out 0.125s;\n}\n.dropdown-list__item--active[data-v-bb78a1dc] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n  background-position: 95% center;\n  background-repeat: no-repeat;\n}\n", ""]);
// Exports
module.exports = exports;
