(<template>
  <div class="language-dropdown">
    <button class="language-dropdown__btn"
            :class="{'language-dropdown__btn--active': langDropdownActive}"
            @click="showTranslationDropdown">{{ currentValue.name }}</button>
    <div v-if="langDropdownActive"
         v-click-outside="handleClickOutside"
         class="language-dropdown__list dropdown-list">
      <div class="dropdown-list__wrapper">
        <button v-for="(lang, index) in langs"
                :key="index"
                class="dropdown-list__item"
                :class="{'dropdown-list__item--active': lang.id === $language.current}"
                @click="setLang(lang.id)">
          <span class="dropdown-list__name">{{ lang.name }}</span>
        </button>
      </div>
    </div>
  </div>
</template>)

<script>
  import constants from '@/modules/constants';
  import {mapMutations} from 'vuex';

  export default {
    data() {
      return {
        langDropdownActive: false
      };
    },
    computed: {
      langs() {
        return [
          {id: 'en', name: 'English'},
          {id: 'nb', name: 'Norsk'}
        ];
      },
      currentValue() {
        for (const lang of this.langs) {
          if (lang.id == this.$language.current) {
            return lang;
          }
        }
      }
    },
    methods: {
      ...mapMutations('UserInfoStore', ['setLocale']),
      setDomain() {
        if (window.location.hostname.includes('herokuapp.com')) return window.location.hostname;
        if (window.location.hostname.includes('salita.no')) return '.salita.no';
        return constants.COOKIE_PATH || '.tikktalk.com';
      },
      setLang(id) {
        if (id != this.$language.current) {
          const cookieParams = {path: '/', expires: constants.COOKIE_EXPIRATION_TIME};
          this.$language.current = id;
          if (window.location.hostname !== 'localhost') {
            cookieParams.domain = this.setDomain(); // ie reject cookies with domain: 'localhost'
          }
          this.$cookieManager.setCookie('locale', this.$language.current, cookieParams);
          this.$cookieManager.setCookie('serverLocale', this.$language.current, cookieParams);
          this.$fetcher.setLocale(id, this.$store.state.TTAuthStore.loggedIn).then(() => {
            this.setLocale(this.$language.current);
          });
          this.langDropdownActive = false;
        }
      },
      showTranslationDropdown() {
        this.langDropdownActive = !this.langDropdownActive;
      },
      handleClickOutside() {
        this.langDropdownActive = false;
      }
    },
    beforeMount() {
      for (const lang of this.langs) {
        if (lang.id == this.$route.params.lang) {
          this.setLang(lang.id, lang.name);
        }
      }
    }
  };
</script>

<style scoped>
.language-dropdown {
  position: relative;
  display: flex;
  align-items: center;
  margin: 0 20px;
}

.language-dropdown__btn {
  height: 100%;
  padding-left: 25px;
  border-bottom: 2px transparent solid;
  background-image: url(~@assets/imgs/salita/dashboard_icons/lang_icon.svg);
  background-position: 0 50%;
  background-size: 20px auto;
  background-repeat: no-repeat;
  font-weight: 400;
}

.language-dropdown__btn--active {
  border-bottom: 2px black solid;
}

.language-dropdown__list {
  position: absolute;
  top: 65px;
  z-index: 3;
  width: 160px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0 24px 64px -12px rgba(16, 24, 40, 0.14), 0 1px 3px 0 rgba(16, 24, 40, 0.1), 0 1px 2px 0 rgba(16, 24, 40, 0.06);
}

.dropdown-list__wrapper {
  padding: 8px;
}

.dropdown-list__item {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 8px;
  border-radius: 8px;
}

.dropdown-list__item:hover {
  background-color: #f4f5f7;
  transition: ease-in-out 0.125s;
}

.dropdown-list__item--active {
  background-image: url(~@assets/imgs/salita/dashboard_icons/check_mark_icon.svg);
  background-position: 95% center;
  background-repeat: no-repeat;
}
</style>
