(<template>
  <div class="translation-dropdown">
    <button class="translation-dropdown__btn"
            :class="{'translation-dropdown__btn--active': translationDropdownActive}"
            @click="showTranslationDropdown">{{ $pgettext('translate', 'Translation') }}</button>
    <div v-if="translationDropdownActive"
         v-click-outside="closeDropdown"
         class="dropdown-list">
      <div class="dropdown-list__wrapper">
        <router-link v-for="(link, index) in filteredLinks"
                     :key="index"
                     :to="link.action"
                     class="dropdown-list__item dropdown-link">
          <div class="dropdown-link__image"
               :class="link.iconClass"></div>
          <span class="dropdown-link__text">{{ link.text }}</span>
        </router-link>
      </div>
    </div>
  </div>
</template>)

<script>
  import {mapGetters} from 'vuex';

  export default {
    data() {
      return {
        translationDropdownActive: false
      };
    },
    computed: {
      ...mapGetters('UserInfoStore', ['userCanShowStatistics']),
      links() {
        return [
          {
            text: this.$gettext('Translation assignments'),
            type: 'router-link',
            iconClass: 'translation-assignments-icon',
            action: this.$makeRoute({name: 'BuyerAllProjects'}),
            condition: true
          },
          {
            text: this.$gettext('Translation statistics'),
            type: 'router-link',
            iconClass: 'translation-statistics-icon',
            action: this.$makeRoute({name: 'BuyerStatisticsChart', query: {view: 'translation'}, params: {chartId: 'line_type'}}),
            condition: this.userCanShowStatistics
          }
        ];
      },
      filteredLinks() { return this.links.filter((link) => link.condition); }
    },
    watch: {
      $route() {
        this.closeDropdown();
      }
    },
    methods: {
      showTranslationDropdown() {
        this.translationDropdownActive = !this.translationDropdownActive;
      },
      closeDropdown() {
        this.translationDropdownActive = false;
      }
    }
  };
</script>

<style scoped>
.translation-dropdown {
  position: relative;
  display: flex;
  align-items: center;
  margin-left: 20px;
}

.translation-dropdown__btn {
  height: 100%;
  padding: 0 30px 0 10px;
  border-bottom: 2px transparent solid;
  background-image: url(~@assets/imgs/salita/dashboard_icons/arrow_down_black.svg);
  background-position: calc(100% - 8px) center;
  background-size: 19px auto;
  background-repeat: no-repeat;
}

.translation-dropdown__btn--active {
  border-bottom: 2px black solid;
  background-image: url(~@assets/imgs/salita/dashboard_icons/arrow_up_black.svg);
}

.dropdown-list {
  position: absolute;
  top: 65px;
  z-index: 3;
  width: 280px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0 24px 64px -12px rgba(16, 24, 40, 0.14), 0 1px 3px 0 rgba(16, 24, 40, 0.1), 0 1px 2px 0 rgba(16, 24, 40, 0.06);
}

.dropdown-list__wrapper {
  padding: 8px;
}

.dropdown-list__item {
  display: flex;
  align-items: center;
  padding: 8px;
  border-radius: 8px;
}

.dropdown-list__item:hover {
  background-color: #f4f5f7;
  transition: ease-in-out 0.125s;
}

.dropdown-link__image {
  width: 20px;
  height: 20px;
  margin-right: 8px;
  background-position: center;
  background-repeat: no-repeat;
}

.translation-assignments-icon {
  background-image: url(~@assets/imgs/salita/dashboard_icons/translation_dropdown/translation_assignments_icon.svg);
}

.translation-statistics-icon {
  background-image: url(~@assets/imgs/salita/dashboard_icons/translation_dropdown/translation_statistics_icon.svg);
}
</style>
