<template>
  <div class="book-btn-menu">
    <button class="book-btn"
            :class="{'book-btn--active': bookMenuActive}"
            @click="showBookMenu">{{ $gettext('Book') }}</button>
    <div v-if="bookMenuActive"
         v-click-outside="closeDropdown"
         class="menu-list">
      <router-link v-for="(link, index) in links"
                   :key="index"
                   :to="link.action"
                   class="menu-list__item menu-link">
        <div class="menu-link__image"
             :class="link.iconClass"></div>
        <div class="menu-link__inner-wrapper">
          <span class="menu-link__subtitle">{{ link.subtitle }}</span>
          <span class="menu-link__text">{{ link.text }}</span>
        </div>
        <div class="menu-link__arrow-icon"></div>
      </router-link>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        bookMenuActive: false
      };
    },
    computed: {
      links() {
        return [
          {
            subtitle: this.$gettext('Interpreter'),
            text: this.$gettext('Speech Language Interpreter'),
            type: 'router-link',
            iconClass: 'book-interpreter-icon',
            action: this.$makeRoute({name: 'BuyerPostInterpretation'})
          },
          {
            subtitle: this.$gettext('Translator'),
            text: this.$gettext('Documents, files, etc.'),
            type: 'router-link',
            iconClass: 'order-translation-icon',
            action: this.$makeRoute({name: 'BuyerPostTranslationProject'})
          },
          {
            subtitle: this.$gettext('Sign language translator'),
            text: this.$gettext('Gestures, facial expressions and body language'),
            type: 'router-link',
            iconClass: 'book-sing-lang-translator',
            action: this.$makeRoute({name: 'BuyerPostInterpretation'})
          },
          {
            subtitle: this.$gettext('Other services'),
            text: this.$gettext('Subtitling, conference interpreting, etc.'),
            type: 'router-link',
            iconClass: 'other-service',
            action: this.$makeRoute({name: 'BuyerPostInterpretation'})
          }
        ];
      }
    },
    watch: {
      $route() {
        this.closeDropdown();
      }
    },
    methods: {
      showBookMenu() {
        this.bookMenuActive = !this.bookMenuActive;
      },
      closeDropdown() {
        this.bookMenuActive = false;
      }
    }
  };
</script>

<style scoped>
.book-btn-menu {
  position: relative;
  display: flex;
  align-items: center;
  margin-left: 20px;
}

.book-btn {
  height: 35px;
  padding: 0 30px 0 10px;
  border-radius: 3px;
  background-color: var(--color-primary);
  background-image: url(~@assets/imgs/salita/dashboard_icons/arrow_down_white.svg);
  background-position: calc(100% - 10px) center;
  background-size: 19px auto;
  background-repeat: no-repeat;
  color: #fff;
}

.book-btn--active {
  background-image: url(~@assets/imgs/salita/dashboard_icons/arrow_up_white.svg);
}

.menu-list {
  position: absolute;
  top: 60px;
  z-index: 3;
  width: 400px;
  padding: 8px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0 24px 64px -12px rgba(16, 24, 40, 0.14), 0 1px 3px 0 rgba(16, 24, 40, 0.1), 0 1px 2px 0 rgba(16, 24, 40, 0.06);
}

.menu-list__item {
  display: flex;
  align-items: center;
  padding: 8px;
  border-radius: 8px;
}

.menu-list__item:hover {
  background-color: #f4f5f7;
  transition: ease-in-out 0.125s;
}

.menu-link__inner-wrapper {
  display: flex;
  flex-direction: column;
  margin: 0 12px;
}

.menu-link__text {
  color: #4d4d4d;
}

.menu-list__item:hover .menu-link__arrow-icon {
  margin-right: 10px;
}

.menu-link__image {
  width: 48px;
  height: 48px;
  border-radius: 8px;
  background-color: #f4f5f7;
  background-position: center;
  background-repeat: no-repeat;
}

.menu-list__item:hover .menu-link__image {
  background-color: #fff;
}

.book-interpreter-icon {
  background-image: url(~@assets/imgs/salita/dashboard_icons/interpreter_black_icon.svg);
}

.order-translation-icon {
  background-image: url(~@assets/imgs/salita/dashboard_icons/translator_black_icon.svg);
}

.book-sing-lang-translator {
  background-image: url(~@assets/imgs/salita/dashboard_icons/sign_language_translator_black_icon.svg);
}

.other-service {
  background-image: url(~@assets/imgs/salita/dashboard_icons/other_services_black_icon.svg);
}

.menu-link__arrow-icon {
  width: 16px;
  height: 16px;
  margin-left: auto;
  background-image: url(~@assets/imgs/salita/dashboard_icons/arrow_to_right_grey.svg);
  background-repeat: no-repeat;
}
</style>
