(<template>
  <div class="interpretation-dropdown">
    <button class="interpretation-dropdown__btn"
            :class="{'interpretation-dropdown__btn--active': interpretationDropdownActive}"
            @click="showInterpretationDropdown">{{ $gettext('Interpretation') }}</button>
    <div v-if="interpretationDropdownActive"
         v-click-outside="closeDropdown"
         class="dropdown-list">
      <div class="dropdown-list__wrapper">
        <router-link v-for="(link, index) in filteredLinks"
                     :key="index"
                     :to="link.action"
                     class="dropdown-list__item dropdown-link">
          <div class="dropdown-link__image"
               :class="link.iconClass"></div>
          <span class="dropdown-link__text">{{ link.text }}</span>
        </router-link>
      </div>
      <hr>
      <div class="dropdown-list__wrapper">
        <router-link v-for="(link, index) in filteredSpecialLinks"
                     :key="index"
                     :to="link.action"
                     class="dropdown-list__item dropdown-link">
          <div class="dropdown-link__image"
               :class="link.iconClass"></div>
          <span class="dropdown-link__text">{{ link.text }}</span>
        </router-link>
      </div>
    </div>
  </div>
</template>)

<script>
  import {mapGetters} from 'vuex';

  export default {
    data() {
      return {
        interpretationDropdownActive: false
      };
    },
    computed: {
      ...mapGetters('UserInfoStore', [
        'userCanShowStatistics',
        'isEmailVerificationRequired'
      ]),
      assignmentsLink() {
        return this.$makeRoute({
          name: 'BuyerAllAssignments',
          query: {
            view: 'current',
            sort: 1,
            page: 1
          }
        });
      },
      calendarLink() {
        return this.$makeRoute({
          name: 'BuyerCalendar',
          query: {
            view: 'week',
            date: this.$moment().format('YYYY-MM-DD')
          }
        });
      },
      capacityLink() {
        return this.$makeRoute({
          name: 'BuyerCapacity',
          query: {
            view: 'day',
            date: this.$moment().format('YYYY-MM-DD'),
            page: 1
          }
        });
      },
      interpretersLink() {
        return this.$makeRoute({
          name: 'BuyerInterpreters',
          query: {
            page: 1,
            availability_date: this.$moment().format('YYYY-MM-DD'),
            langFrom: '3',
            relation: true
          }
        });
      },
      links() {
        return [
          {
            text: this.$gettext('Interpretation assignments'),
            type: 'router-link',
            iconClass: 'interpretation-assignments-icon',
            action: this.assignmentsLink,
            condition: true
          },
          {
            text: this.$gettext('Calendar'),
            type: 'router-link',
            iconClass: 'calendar-icon',
            action: this.calendarLink,
            condition: true
          },
          {
            text: this.$gettext('Interpretation statistics'),
            type: 'router-link',
            iconClass: 'interpretation-statistics-icon',
            action: this.$makeRoute({name: 'BuyerStatisticsChart', query: {view: 'interpretation'}, params: {chartId: 'line_type'}}),
            condition: this.userCanShowStatistics
          }
        ];
      },
      filteredLinks() { return this.links.filter((link) => link.condition); },
      specialLinks() {
        return [
          {
            text: this.$gettext('Favorite interpreters'),
            type: 'router-link',
            iconClass: 'favorite-interpreters-icon',
            action: this.interpretersLink,
            condition: true
          },
          {
            text: this.$gettext('Book specific interpreter'),
            type: 'router-link',
            iconClass: 'book-specific-interpreter-icon',
            action: this.capacityLink,
            condition: !this.isEmailVerificationRequired
          }
        ];
      },
      filteredSpecialLinks() { return this.specialLinks.filter((link) => link.condition); }
    },
    watch: {
      $route() {
        this.closeDropdown();
      }
    },
    methods: {
      showInterpretationDropdown() {
        this.interpretationDropdownActive = !this.interpretationDropdownActive;
      },
      closeDropdown() {
        this.interpretationDropdownActive = false;
      }
    }
  };
</script>

<style scoped>
hr {
  height: 1px;
  border: none;
  background-color: #e4e6eb;
}

.interpretation-dropdown {
  position: relative;
  display: flex;
  align-items: center;
  margin-left: 20px;
}

.interpretation-dropdown__btn {
  height: 100%;
  padding: 0 30px 0 10px;
  border-bottom: 2px transparent solid;
  background-image: url(~@assets/imgs/salita/dashboard_icons/arrow_down_black.svg);
  background-position: calc(100% - 8px) center;
  background-size: 19px auto;
  background-repeat: no-repeat;
}

.interpretation-dropdown__btn--active {
  border-bottom: 2px black solid;
  background-image: url(~@assets/imgs/salita/dashboard_icons/arrow_up_black.svg);
}

.dropdown-list {
  position: absolute;
  top: 65px;
  z-index: 3;
  width: 280px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0 24px 64px -12px rgba(16, 24, 40, 0.14), 0 1px 3px 0 rgba(16, 24, 40, 0.1), 0 1px 2px 0 rgba(16, 24, 40, 0.06);
}

.dropdown-list__wrapper {
  padding: 8px;
}

.dropdown-list__item {
  display: flex;
  align-items: center;
  padding: 8px;
  border-radius: 8px;
}

.dropdown-list__item:hover {
  background-color: #f4f5f7;
  transition: ease-in-out 0.125s;
}

.dropdown-link__image {
  width: 20px;
  height: 20px;
  margin-right: 8px;
  background-position: center;
  background-repeat: no-repeat;
}

.interpretation-assignments-icon {
  background-image: url(~@assets/imgs/salita/dashboard_icons/interpretation_dropdown/interpretation_assignments_icon.svg);
}

.calendar-icon {
  background-image: url(~@assets/imgs/salita/dashboard_icons/interpretation_dropdown/calendar_icon.svg);
}

.interpretation-statistics-icon {
  background-image: url(~@assets/imgs/salita/dashboard_icons/interpretation_dropdown/interpretation_statistics_icon.svg);
}

.favorite-interpreters-icon {
  background-image: url(~@assets/imgs/salita/dashboard_icons/interpretation_dropdown/favorite_interpreters_icon.svg);
}

.book-specific-interpreter-icon {
  background-image: url(~@assets/imgs/salita/dashboard_icons/interpretation_dropdown/book_specific_interpreter_icon.svg);
}
</style>
